import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Features = ({ slice }) => {
  const {title} = slice.primary

  const items = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-sm-6">
        <div className="h-100 bg-white rounded p-4">
        <div className="row">
          <div className="col d-flex flex-column justify-content-between">
            <h5 className="">{item.title}</h5>
            <p className="mt-3 mb-0">{item.text.text}</p>
          </div>
          <div className="col-auto">
            <div className="bg-light rounded d-flex justify-content-center align-items-center" style={{height:"92px",width:"92px"}}>
              <img className="" style={{objectFit:"cover"}} height="64" width="64"  src={item.img.url}/>
            </div>
          </div>
        </div>
        
      </div>
      </div>
    )
  })

  return (
    <div className="bg-light py-5">
      <div className="container py-5">
        <div className="col-lg-8 mx-auto">
          <h3 className="text-center">{title}</h3>
        </div>
        <div className="row g-4 mt-2">
          {items}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyFeatures on PrismicPageDataBodyFeatures {
    primary {
      title
    }
    items {
      title
      text {
        text
      }
      img {
        url
      }
    }
  }
`
