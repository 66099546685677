import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import Carousel from 'react-bootstrap/Carousel';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { useLocation } from '@reach/router';
import translate from '../utils/translator'
import moment from 'moment';

import {convertPrismicRest, convertPrismicStory} from '../utils/utils'

const ViewMode = {
  Loading: 'loading',
  Day: 'day',
  Week: 'week',
}
const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
]
const weekData = [
  {code: 'week', name: 'Woche',},
  {code: 'monday',name: 'Montag',},
  {code: 'tuesday',name: 'Dienstag',},
  {code: 'wednesday',name: 'Mittwoch',},
  {code: 'thursday',name: 'Donnerstag',},
  {code: 'friday',name: 'Freitag',},
]
const weekDays = {}
weekData.forEach(item => {
  weekDays[item.code] = item.name
})

export const WeekMenu = ({ slice, context }) => {
  console.log('slice: ', slice)
  console.log('context: ', context)
  const {id, items} = slice

  const [rests, setRests] = React.useState([])
  const [story, setStory] = React.useState([])
  const [dishes, setDishes] = React.useState([])

  const [state, setState] = React.useState({
    week: 1,
    dates: '',
    day: '',
    mode: ViewMode.Loading,
  });
  React.useEffect(() => {
    // Runs ONCE after initial rendering
    const week = context.weekNumber
    const nowDay = moment().startOf('year').add(week, 'weeks')
    const startWeek = nowDay.startOf('isoWeek').format('DD.MM')
    const endWeek = nowDay.endOf('isoWeek').format('DD.MM.YYYY')
    const dates = `${startWeek} - ${endWeek}`
    // setState({day: '', week, dates, mode: ViewMode.Loading})
    //
    const currentWeekDay = moment().isoWeekday()
    console.log("currentWeekDay",currentWeekDay)
    const _rests = slice.items.map(item => {
      return convertPrismicRest({item, rests: context.rests})
    }).sort((a,b) => {
      if (a.featured == b.featured === true) return 0
      if (a.featured === true) return -1
      return 1
    })
    console.log('sorted: ', _rests)
    setRests(_rests)
    //
    const _story = slice.primary?.link_to_story?.document
    if (_story && _story.type === 'stories') {
      console.log('story uid: ', _story.uid)
      const _sBody = _story.data?.body || []
      console.log('story data: ', _sBody)
      const _stories = _sBody.find(item => item.slice_type === 'story')
      console.log('_stories: ', _stories)
      if (_stories && _stories.items) {
        const _s = _stories.items.map(item => {
          return convertPrismicStory({item, rests: context.rests})
        })
        setStory(_s)
      }
    }
    //
    const hash = getHash()
    console.log('hash: ', hash)
    let mode = ViewMode.Week, day = ''
    if (hash && days.includes(hash)) {
      mode = ViewMode.Day
      day = hash
    }
    moment.locale('en')
    const d = moment()
    const wd = d.format('dddd').toLowerCase();
    console.log(`wd: ${wd}`)
    if (days.includes(wd) && (window.innerWidth <= 960)) {
      // isMobile
      mode = ViewMode.Day
      day = wd
    }
    setState(prevState => { return {...prevState, mode, day, week, dates} })
    const dish = context.dishes.find(item => {
      // console.log('dish: ', item);
      const {rest_code} = item.primary;
      console.log('rest_code: ', rest_code);
      return rest_code === context.restCode;
    });
    console.log('> dish: ', dish);
    if (dish) {
    setDishes(dish.items)
    }
  }, [])

  const onChangeCombo = (event) => {
    // console.log('event: ', event)
    const {target} = event
    const {name, value} = target
    console.log('combo: %s value: %s', name, value)
    if (value === 'week') {
      window.location.hash=''
      setState(prevState => {
        return {...prevState, mode: ViewMode.Week, day: ''}
      })
    } else {
      window.location.hash=`${value}`
      setState(prevState => {
        return {...prevState, mode: ViewMode.Day, day: value}
      })
    }
  }

  const SingleCell = ({cell}) => {
    const {date,day,code,name, phone, img, location, singleCell} = cell
    return (
      <>
        {/** first cell with name and logo */}
        <div className="col-2 p-4 border-bottom border-end d-flex flex-column align-items-center">         
          <img src={img} style={{width:"100%",maxWidth:"120px",maxHeight:"96px",objectFit:"contain"}} className=""></img>
          <div className="mt-3 row gx-3">
				  	{location &&
              <div className="col">
				  		  <a href={location} target="_blank" type="button" className="rounded-circle bg-info p-2 bg-opacity-10">
                  <img className="icon-info" height="24"   width="24" src={"/icons/location.svg"} alt=""/>
                </a>
              </div>
            }
				  	{phone &&
              <div className="col">
                <a href={"tel:"+phone} target="_blank" type="button" className="rounded-circle bg-success p-2 bg-opacity-10">
                  <img className="icon-success" height="24"   width="24" src={"/icons/phone.svg"} alt=""/>
                </a>
              </div>
				  	}
				  </div>
        </div>
        {/** data */}
        <div className="col-10 d-flex flex-column justify-content-center p-4 border-bottom" dangerouslySetInnerHTML={{__html: singleCell}} />
      </>
    )
  }

  const WeekCells = ({rest}) => {
    const {date,day,code,name, phone, img, url, location, weekData, singleCell} = rest
    console.log("singleCell ",singleCell)
    return (
      <>
        {/** data */}
        <div className="col-12 d-flex flex-column border-bottom">
          {(singleCell && singleCell != "<p></p>") &&
            <div className="flex-fill d-flex flex-column justify-content-center p-4 single-cell" dangerouslySetInnerHTML={{__html: singleCell}} />
          }
          {!rest.isSingle &&
          <div className="flex-fill row m-0 w-100">
            {days.map((item, index) => (
              <div key={index} className={"col p-4 text-break" + " " + ((days.length-1 !== index) ? "border-end" : "")} style={{width:"20%"}} dangerouslySetInnerHTML={{__html: weekData[item]}}/>
            ))}
          </div>
          }
        </div>
      </>
    )
  }

  const RestaurantWeekMenu = ({rest}) => {
    //
    return (
      /*<>
        {rest.isSingle ? 
          <SingleCell cell={rest} /> :
          <WeekCells week={rest} />
        }
      </>*/
      <WeekCells rest={rest} />
    )
  }
  // render mode
  const renderLoading = () => (
    <p>Loading mode</p>
  )
  const RenderDayRest = ({rest}) => {
    /*const day = rest.isSingle ?
      rest.singleCell :
      rest.weekData[state.day]*/
    const day = rest.weekData[state.day]

    if (!day) return (<></>)

    const {name, phone, img, url, location, weekData, singleCell, isAllDishes} = rest
    
    return (
      <>
        {/** data */}
        <div className="col-12 col-sm-8 col-md-9 col-xl-10 p-4 border-bottom">
          {(singleCell && singleCell != "<p></p>") &&
            <div className="flex-fill d-flex flex-column justify-content-center p-4 single-cell" dangerouslySetInnerHTML={{__html: singleCell}} />
          }
          {isAllDishes?
            <div className="p-4">
              {days.map((item, index) => (
                <div key={index} className={"text-break" + " " + ((days.length-1 !== index) ? "border-end" : "")} dangerouslySetInnerHTML={{__html: weekData[item]}}/>
              ))}
            </div>
          :
            <div dangerouslySetInnerHTML={{__html: day}} />
          }
        </div>
      </>
    )
  }
  // see fields: src/utils/utils.js -> outRest
  const renderDay = () => {
    const dayName = weekDays[state.day]

    return (
      <div className="mt-3" style={{overflowX:"scroll"}}>
        <div className="bg-white rounded-3 overflow-hidden text-break" style={{fontSize:"14px"}}>
          <div className="p-4 d-flex flex-column justify-content-center border-bottom">
            <div className="h5 mb-0 text-uppercase align-middle">{dayName}</div>
          </div>
          {/** DATA */}
          <>
            {rests.map(item => (
              <div key={item.code}  className="row g-0">
                <RenderDayRest rest={item} />
              </div>
            ))}
          </>
        </div>
      </div>
    )
  }
  // see fields: src/utils/utils.js -> outRest
  const renderWeek = () => (
    <div className="mt-3" style={{overflowX:"scroll"}}>
      <div className="bg-white rounded-3 overflow-hidden" style={{fontSize:"14px",minWidth:"1296px"}}>
        <div className="row g-0">
          {/** HEADER */}
          {/** week day names */}
          {days.map((item, index) => (
            <div key={index} className={"col-2 p-4 d-flex flex-column justify-content-center border-bottom " + ((days.length-1 !== index) ? "border-end" : "")} style={{width:"20%"}} >
              <div className="h5 mb-0 text-uppercase align-middle">{weekDays[item]}</div>
            </div>
          ))}
        </div>
        {/** DATA */}
        <>
          {rests.map(item => (
            <div className="row g-0" key={item.code}>
              <RestaurantWeekMenu rest={item} />
            </div>
          ))}
        </>
      </div>
    </div>
  )
  const renderMap = {
    [ViewMode.Loading] : renderLoading,
    [ViewMode.Week] : renderWeek,
    [ViewMode.Day] : renderDay,
  }

  console.log('render mode: ', state.mode)
  const Component = renderMap[state.mode]

  const RenderRestInfo = ({rest}) => {
    const {name, phone, email, img, url, address, location, cta, weekData, singleCell} = rest
    return (
      <div className="bg-white rounded-3 overflow-hidden mb-4 testimonial-group position-relative" style={{marginTop:"-240px"}}>
        <div className="p-5">
          <div className="row gy-4">
            <div className="col-12 col-lg-6">
              <img src={img} style={{width:"100%",maxWidth:"120px",maxHeight:"96px",objectFit:"contain", objectPosition: "left bottom"}} className=""></img>
              <div className="mt-3">
                <p className="font-bebas mb-0" style={{fontSize:"72px",lineHeight:"initial"}}>{name}</p>
                <h6 className="mb-0">{address}</h6>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-end no-m-last-p">
              <PrismicRichText field={cta.richText} />
            </div>
          </div>
          <div className="mt-0 row g-5">
            {location &&
              <div className="col-auto">
                <a href={location} target="_blank" className="d-flex align-items-center color-main">
                  <div className="rounded-circle d-flex justify-content-center align-items-center" style={{height:"44px",width:"44px",backgroundColor:"#1b66b3"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#fff" className="bi bi-geo-alt" viewBox="0 0 16 16"><path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/><path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>
                  </div>
                  <h4 className="ms-3 mb-0 text-uppercase font-bebas">{"Route berechnen"}</h4>
                </a>
              </div>
            }
            {phone &&
              <div className="col-auto">
                <a href={"tel:" + phone} target="_blank" className="d-flex align-items-center color-main">
                  <div className="rounded-circle d-flex justify-content-center align-items-center" style={{height:"44px",width:"44px",backgroundColor:"#1b66b3"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#fff" className="bi bi-phone" viewBox="0 0 16 16"><path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/><path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>
                  </div>
                  <h4 className="ms-3 mb-0 text-uppercase font-bebas">{phone}</h4>
                </a>
              </div>
            }
            {email &&
              <div className="col-auto">
                <a href={"mailto:" + email} target="_blank" className="d-flex align-items-center color-main">
                  <div className="rounded-circle d-flex justify-content-center align-items-center" style={{height:"44px",width:"44px",backgroundColor:"#1b66b3"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#fff" className="bi bi-envelope" viewBox="0 0 16 16"><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/></svg>
                  </div>
                  <h4 className="ms-3 mb-0 text-uppercase font-bebas">{email}</h4>
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
  const galleryImages = dishes.map((items, index) => {
    const imgUrl = items.dish.url;
    return (
      <Carousel.Item key={index} interval={2000} className="ar16-9">
        <Item
          key={index}
          original={imgUrl}
          thumbnail={imgUrl}
          width={2400}
          height={2400}
        >
          {({ ref, open }) => (
            <img
              ref={ref}
              onClick={open}
              src={imgUrl}
              alt={""}
              className="image-cover"
            />
          )}
        </Item>
      </Carousel.Item>
      );
    });

    const BottomInfo = ({rest}) => {
      const {text, text_2} = rest
      return (
        <>
        {text &&
          <div className="bg-white rounded-3 overflow-hidden py-4 px-3 p-lg-5 mt-5">
            <PrismicRichText field={text.richText} />
          </div>
        }
        {text_2 &&
          <div className="bg-white rounded-3 overflow-hidden py-4 px-3 p-lg-5 mt-5">
            <PrismicRichText field={text_2.richText} />
          </div>
        }
        </>
      )
    }


            
  return (
    <div  className="h-100 bg-light wide border-bottom">
      <img src={slice.primary.bg_img.url} style={{width:"100%",minHeight:"320px",maxHeight:"440px",objectFit:"cover"}} className=""></img>
      <div className="p-md-075rem container-fluid container-xxl py-5">
          {rests.map(item => (
            <div key={item.code}>
              <RenderRestInfo rest={item} />
            </div>
          ))}
        <RenderCombo
          name={'Select view mode'}
          options={weekData}
          value={state.day}
          onChange={onChangeCombo}
        />
        <h5 className="text-center mt-4 mb-3">{state.dates}</h5>
        <Component />
        <div className="row mt-5">
          <h5 className="text-center">Leckere Einblicke der Gerichte</h5>
          <div className="col-12 col-lg-9 mx-auto">
            <Gallery>
              <Carousel  controls={(galleryImages.length > 1) ? true : false} indicators={(galleryImages.length > 1) ? true : false}>
                {galleryImages}
              </Carousel>
            </Gallery>
          </div>
        </div>
        {rests.map(item => (
          <div key={item.code}>
            <BottomInfo rest={item} />
          </div>
        ))}
      </div>
    </div>
  )
}
//------
const RenderCombo = ({name, options, value, onChange}) => (
  <div className="d-flex justify-content-center">
    <select name={name} value={value} onChange={onChange} className="form-select w-auto" aria-label="Default select example">
      {options.map((item, index) => {
        return (
          <option
            key={index}
            value={item.code}
          >{item.name}</option>)
      })}
    </select>
  </div>
)
const getHash = () => {
  const search = window.location.search
  // console.log(search)
  const _hash = window.location.hash
  const hash = _hash ? _hash.substring(1) : ''
  return hash
}

export const query = graphql`
  fragment MenuDayMainSlice on PrismicWeekMenuDataBodyMainSlice {
    id
    slice_label
    slice_type
    items {
      date
      day_or_week
      featured
      restaurant
      single_cell {
        html
      }
      single_day_menu {
        html
      }
      monday_menu {
        html
      }
      tuesday_menu {
        html
      }
      wednesday_menu {
        html
      }
      thursday_menu {
        html
      }
      friday_menu {
        html
      }
    }
    primary {
      bg_img {
        url
      }
      link_to_story {
        id
        link_type
        # document {
        #   ... on PrismicStories {
        #     id
        #     type
        #     uid
        #     data {
        #       body {
        #         ... on PrismicStoriesDataBodyStory {
        #           id
        #           slice_type
        #           items {
        #             date
        #             restaurant
        #             image_url
        #             image {
        #               url
        #             }
        #           }
        #         }
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`
