import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Pricing = ({ slice }) => {
  const { options_title, options_list, options_text } = slice.primary

  const tables = slice.items.map((item, index) => {
    return (
      <div key={index} className={"col-12 " + " " + ((index==0) ? "col-lg-7" : "col-lg-5")}>
        <div className="card h-100">
          <div className={"card-header rounded-0 text-white pt-3 pb-1" + " " + ((index==0) ? "bg-primary" : "bg-dark")}>
            <PrismicRichText field={item.table_title.richText} />
          </div>
          <div className={"card-body border border-5 italic-as-red-text" + " " + ((index==0) ? "bg-dark text-white border-primary" : "border-dark")}>
            <PrismicRichText field={item.table_text.richText} />
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <div className="row g-4">
          {tables}
        </div>
        <div className="mt-4">
          <PrismicRichText field={options_title.richText} />
          <div className="list-3-cols">
            <PrismicRichText field={options_list.richText} />
          </div>
          <PrismicRichText field={options_text.richText} />
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPricing on PrismicHomepageDataBodyPricing {
    primary {
      options_title {
        richText
      }
      options_list {
        richText
      }
      options_text {
        richText
      }
    }
    items {
      table_title {
        richText
      }
      table_text {
        richText
      }
    }
  }
`
