import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Reviews = ({ slice }) => {
  const {title, review_1, review_2, review_3, img_1, img_2 } = slice.primary

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <img className="d-block d-lg-none" style={{objectFit:"contain"}} height="400" src={img_1.url}/>
        <PrismicRichText field={review_1.richText} />
        <div className="row">
          <div className="col-12 col-lg-4 d-none d-lg-block">
            <img className="w-100" style={{objectFit:"contain"}} src={img_1.url}/>
          </div>
          <div className="col-12 col-lg-8">
            <PrismicRichText field={review_2.richText} />
            <div className="row">
              <div className="col-3">
                <img className="w-100" style={{objectFit:"contain"}} src={img_2.url}/>
              </div>
              <div className="col-9">
                <PrismicRichText field={review_3.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyReviews on PrismicHomepageDataBodyReviews {
    primary {
      title
      review_1 {
        richText
      }
      review_2 {
        richText
      }
      review_3 {
        richText
      }
      img_1 {
        url
      }
      img_2 {
        url
      }
    }
  }
`
