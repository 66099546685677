import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const PageCover = ({ slice }) => {
  const {title, subtitle, cover_img, side_img, btn_label, btn_anchor} = slice.primary

  return (
    <div className="bg-dark d-flex flex-column" style={{backgroundImage:"url(" + cover_img.url + ")",backgroundPosition:"center center", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
      <div className="homepage-cover-gradient">
        <div className="py-5">
          <div className="position-relative container py-5">
            <div className="row g-4">
              <div className="col-12 col-xl-7 d-flex flex-column justify-content-center align-items-start">
                <h1 className="text-white h2">{title}</h1>
                <h4 className="text-white mt-4">{subtitle}</h4>
                <a href={"#" + btn_anchor} className="mt-4 btn btn-lg btn-primary px-4">{btn_label}</a>
              </div>
              <div className="col-12 col-xl-5 pe-3">
                <img className="" style={{objectFit:"contain", width:"100%", maxHeight:"480px", marginBottom:"-96px"}}  src={side_img.url}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyPageCover on PrismicPageDataBodyPageCover {
    primary {
      title
      subtitle
      cover_img {
        url
      }
      side_img {
        url
      }
      btn_label
      btn_anchor
    }
  }
`
