import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Marketing = ({ slice }) => {
  const { title, text, list_1, list_2, list_3, cover_img } = slice.primary

  return (
    <div className="bg-dark d-flex flex-column" style={{backgroundImage:"url(" + cover_img.url + ")",backgroundPosition:"center center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
      <div className="bg-img-gradient py-5">
        <div className="container py-5">
          <h3 className="text-white">{title}</h3>
          <div className="row mt-4">
            <div className="col-12 col-lg-5 text-white d-flex flex-column justify-content-center">
              <PrismicRichText field={text.richText} />
            </div>
            <div className="col-12 col-lg-7">
              <div className="bg-light mp-m-p-or-ul p-3">
                <PrismicRichText field={list_1.richText} />
              </div>
              <div className="bg-light mp-m-p-or-ul p-3 mt-4">
                <PrismicRichText field={list_2.richText} />
              </div>
              <div className="bg-light mp-m-p-or-ul p-3 mt-4">
                <PrismicRichText field={list_3.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMarketing on PrismicHomepageDataBodyMarketing {
    primary {
      title
      list_1 {
        richText
      }
      list_2 {
        richText
      }
      list_3 {
        richText
      }
      text {
        richText
      }
      cover_img {
        url
      }
    }
  }
`
