import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';

export const Contacts = ({ slice }) => {
  const {title, logo, qr_code, text, cover_img, form_title, form_url} = slice.primary

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  
  const onSubmit = async (data) => {
    console.log('data: ', data);
    const url = "https://n8n.yohn.io/webhook/website-forms";
    const r = await callHook({url, uid:"city-eats-contact-form", params:data});
    console.log(r);
    // if (r && r.success === true) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
    // setValue("full", "");
    setSend(true);
  }

  const ContactForm = ({ }) => {
    if (isSend) {
      return (
        <div className="text-start">
          {!isError && (
          <>
            <h3>Danke!</h3>
            <p>Wir werden Sie in Kürze kontaktieren.</p>
          </>
          )}
          {isError && (
          <>
            <h3>An error has occurred</h3>
            <p>Please try again later, server is not available now.</p>
          </>
          )}
          <div className="col-auto">
            <button onClick={()=>setSend(false)} className={"btn text-uppercase btn-primary"}>{isError ? "Try again" : "Wiederholen"}</button>
          </div>
        </div>
      );
    }

    return (
      <div className="">
        <form className="text-start" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              placeholder="Ihr Name"
              className={'form-control'}
              {...register('name', {required: false})}
            />
          </div>
          <div className={"mt-4"}>
            <input
              placeholder="Ihre E-Mail-Adresse"
              className={"form-control"}
              required
              {...register('email', {required: true})}
            />
          </div>
          <div className={"mt-4"}>
            <textarea
              placeholder="Geben Sie hier Ihre Nachricht ein"
              type="text"
              rows="5"
              className={"form-control"}
              required
              {...register('text', {required: true})}
            />
          </div>
          <input type="submit" value="Nachricht senden" className={"mt-4 btn btn-primary"}/>
        </form>
		  </div>
    )
  }

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <div className="row g-5" >
          <div className="col-12 col-lg-5 ps-0 d-flex flex-column justify-content-end" style={{minHeight:"480px", backgroundImage:"url(" + cover_img.url + ")",backgroundPosition:"top left",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
            <div className="m-4 ms-0 p-4 text-white" style={{maxWidth:"360px", backgroundColor:"#ac0a0890"}}>
              <h3 className="" >{title}</h3>
            </div>
          </div>
          <div className="col-12 col-lg-7 pe-0 d-flex flex-column justify-content-end">
            <div className="">
              <img className="" style={{objectFit:"contain"}} height="48" src={logo.url}/>
              <div className="row mt-3">
                <div className="col">
                  <PrismicRichText field={text.richText} />
                </div>
                <div className="col">
                  <img className="" style={{objectFit:"contain"}} width="120" src={qr_code.url}/>
                </div>
              </div>
            </div>
            <h5 className="mt-4 mb-3" >{form_title}</h5>
            <ContactForm/>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyContacts on PrismicHomepageDataBodyContacts {
    primary {
      title
      text {
        richText
      }
      logo {
        url
      }
      qr_code {
        url
      }
      cover_img {
        url
      }
      form_title
      form_url
    }
  }
`
