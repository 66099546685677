import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Example = ({ slice }) => {
  const { title, logo } = slice.primary
  const images = slice.items.map((item, index) => {
    return (
      <div className="col-12 col-lg-6" key={index} >
        <img className="w-100" style={{objectFit:"contain"}} src={item.img.url}/>
      </div>
    )
  })

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <h2 className="text-center"><img className="mb-2" style={{objectFit:"contain"}} height="52px" src={logo.url}/>  {title}</h2>
        <div className="row g-0">
          {images}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyExample on PrismicHomepageDataBodyExample {
    primary {
      title
      logo {
        url
      }
    }
    items {
      img {
        url
      }
    }
  }
`
