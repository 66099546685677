import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Form from 'react-bootstrap/Form';
import { PrismicRichText } from '@prismicio/react'

export const PricingNew = ({ slice }) => {
  const {title} = slice.primary

	const [isSwitchOn, setIsSwitchOn] = useState(false);

	const onSwitchAction = () => {
	  setIsSwitchOn(!isSwitchOn);
	};

	const pricing = slice.items.map((item, index) => {
    return (
      <div key={index} className={"col-12 col-lg-4  overflow-hidden"}>
				<div className={"d-flex flex-column px-3 h-100 rounded bg-white"}>
					<div className="card-body d-flex flex-column align-content-between py-4">
						<div className="">
							<h5 className="card-title text-uppercase text-center">{item.title}</h5>
							<div className="text-center mt-2 mb-4">
								<span className="h3">{"€ "}</span>
								<span className="h2">{isSwitchOn ? item.price_year : item.price_month}</span>
							</div>
							<hr/>
							<div className="my-4">
								<PrismicRichText field={item.main_features.richText} />
							</div>
						</div>
						<div className="mt-3 h-100 d-flex flex-column justify-content-end">
							<a className={"btn btn-primary text-uppercase"}>{item.btn_label}</a>
						</div>
					</div>
				</div>
			</div>
    )
  })

  return (
    <div className="pricing bg-dark py-5" id="pricing">
			<div className="container py-5">
				<h3 className="text-center text-white mb-5">{title}</h3>
				<div className="d-flex justify-content-center align-items-center text-white">
					<div className="d-flex flex-column justify-content-center align-items-center">
						<h5 className="mb-0">{"Monatlich"}</h5>
					</div>
					<div className="px-4">
						<Form>
    				  <Form.Check
								type="switch"
    				    onChange={onSwitchAction}
								checked={isSwitchOn}
    				  />
    				</Form>
					</div>
					<div className="d-flex flex-column justify-content-center align-items-center">
						<h5 className="mb-0">{"Jährlich"}</h5>
					</div>
				</div>
				<div className="mt-3 overflow-hidden">
					<div className="row gx-4 gy-4">	
						{pricing}
					</div>
				</div>
			</div>
		</div>
  )
}

export const query = graphql`
	fragment PageDataBodyPricingNew on PrismicPageDataBodyPricingNew {
    primary {
      title
    }
    items {
      title
			price_month
			price_year
			main_features {
				richText
			}
			btn_label
    }
  }
`
