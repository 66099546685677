exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-wochenkarte-prismic-week-menu-uid-js": () => import("./../../../src/pages/wochenkarte/{prismicWeekMenu.uid}.js" /* webpackChunkName: "component---src-pages-wochenkarte-prismic-week-menu-uid-js" */),
  "component---src-templates-edeka-bauer-js": () => import("./../../../src/templates/edeka-bauer.js" /* webpackChunkName: "component---src-templates-edeka-bauer-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-week-js": () => import("./../../../src/templates/week.js" /* webpackChunkName: "component---src-templates-week-js" */)
}

