import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  //console.log("footer data:", footer);

  return (
    <Layout topMenu={topMenu.data} footer={footer.data} activeDocMeta={activeDoc}>
      <a href="tel:+497031439872" type="button" className="position-fixed d-none d-lg-block btn btn-lg btn-primary" style={{right:"24px",bottom:"32px",zIndex:"100"}}>Jetzt anrufen!</a>
      <a href="tel:+497031439872" type="button" className="position-fixed d-block d-lg-none btn btn-primary" style={{right:"24px",bottom:"32px",zIndex:"100"}}>Jetzt anrufen!</a>
      <SliceZone slices={homepage.data.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyHomepageCover
          ...HomepageDataBodyImageAndText
          ...HomepageDataBodyReviews
          ...HomepageDataBodyWhatWeOffer
          ...HomepageDataBodyPricing
          ...HomepageDataBodyMarketing
          ...HomepageDataBodyContacts
          ...HomepageDataBodyExample
          ...HomepageDataBodyAdvertising
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(HomepageTemplate)
