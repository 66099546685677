import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const WhatWeOffer = ({ slice }) => {
  const {title, text_1, text_2, img } = slice.primary

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <div className="row g-4">        
          <div className="col-12 col-lg-7 d-flex flex-column justify-content-center">
          <h3 className="">{title}</h3>
            <div className="mt-4 italic-as-red-text">
              <PrismicRichText field={text_1.richText} />
            </div>
            <div className="mt-4 italic-as-red-text">
              <PrismicRichText field={text_2.richText} />
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <img className="w-100" style={{objectFit:"contain", maxWidth:"480px"}} src={img.url}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyWhatWeOffer on PrismicHomepageDataBodyWhatWeOffer {
    primary {
      title
      text_1 {
        richText
      }
      text_2 {
        richText
      }
      img {
        url
      }
    }
  }
`
