import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'

import { LanguageSwitcher } from './LanguageSwitcher'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export const TopMenu = ({ topMenu, activeDocMeta }) => {
  const renderedMenuLinks = topMenu
    ? topMenu.menu_links.map((menuLink, index) => {
      // console.log('menuLink: ', menuLink.link.url)
      let url = menuLink.link.url;

      return (
        <div key={`top-nav-${index}`}>
          <Nav.Link href={url}>{menuLink.label.text}</Nav.Link>
        </div>
        
      )})
    : null

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="border-bottom border-2">
      <div className="container">
      <Navbar.Brand href="/"><img className="" height="40" src={topMenu.logo.url} alt={"Sifi-Eats Logo"}/></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          {renderedMenuLinks}
        </Nav>
      </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      logo {
        url
      }
      menu_links {
        label {
          richText
          html
          text
        }
        link {
          id
          url
        }
      }
    }
  }
`
