//
import moment from 'moment-timezone';
import * as prismicH from '@prismicio/helpers';
//
export const enableDictIfFound = ({item,dict,names}) => {
  if (item) {
    if (Array.isArray(item)) {
      item.forEach(name => {
        if (names.includes(name)) {
          dict[name] = true
        }
      })
    } else {
      if (names.includes(item)) {
        dict[item] = true
      }
    }
  }
}
export const getDisplaySchedule = (time) => {
  if (!time) return 'Ruhetag'
  if (time === 'closed') return 'Ruhetag'
  if (time === '24h') return '24 St. geöffnet'
  return time.trim()
}
//
export const isRestaurantOpen = ({restItem}) => {
  const {type, uid, data} = restItem
  console.log('filter data: ', data)
  const detail = data.body.find(item => {
    return item.slice_type === 'restaurant_detail'
  })
  if (!detail) return true

  const currentWeekDay = moment().isoWeekday() - 1
  console.log('day: ', currentWeekDay)
  const now = moment().utc()
  console.log('now: ', now.format('YYYY-MM-DD HH:mm'))
  const utcOffset = 1

  // console.log('menu: ', detail)
  const p = detail.primary
  const times = [
    p.monday_time,
    p.tuesday_time,
    p.wednesday_time,
    p.thursday_time,
    p.friday_time,
    p.saturday_time,
    p.sunday_time,
  ]
  const openTimes = times[currentWeekDay]
  if (!openTimes) return false
  if (openTimes === 'closed') return false
  if (openTimes === '24h') return true
  console.log('open Berlin: ', openTimes)

  const intervals = openTimes.trim().split(',')
  const isOpen = intervals.some(item => {
    const [openTime, closeTime] = item.split('-')
    const [openHour, openMin] = openTime.split(':')
    let [closeHour, closeMin] = closeTime.split(':')

    if (Number(closeHour) < Number(openHour)) {
      closeHour = Number(closeHour) + 24
    }
    
    const todayOpenTime = moment().tz("Europe/Berlin").startOf('day').add(openHour, 'hours').add(openMin, 'm').utc()
    const todayCloseTime = moment().tz("Europe/Berlin").startOf('day').add(closeHour, 'hours').add(closeMin, 'm').utc()
    console.log('todayOpenTime utc: ', todayOpenTime.format('YYYY-MM-DD HH:mm'))
    console.log('todayCloseTime utc: ', todayCloseTime.format('YYYY-MM-DD HH:mm'))

    const isOpen =  now.isSameOrAfter(todayOpenTime) && now.isSameOrBefore(todayCloseTime)
    console.log('isOpen: ', isOpen)
    return isOpen
  })
  console.log('false')
  return isOpen
}
export const replaceDish = (html) => {
  if (!html) return null;
  // console.log(`html start: ${html}`);
  const replaceCode = 'dish';
  // console.log(`Start replace code: ${replaceCode}`);
  let outHtml = html;
  // variant 1
  outHtml = outHtml.replaceAll(`<p>&lt;/${replaceCode}&gt;</p>`,'</div></div>');
  // variant 2
  outHtml = outHtml.replaceAll(`<br />&lt;/${replaceCode}&gt;</p>`,'</div></div>');

  const replaceFind1 = `<p>&lt;${replaceCode}&gt;</p>`;
  const replaceFind2 = `<p>&lt;${replaceCode}&gt;<br />`;
  const colors = ['#ac0a08','#0EB7DA','#DAAD0E'];
  let replaceWithStart = '<div className="d-flex mb-3 overflow-hidden text-break dish text-left"><div className="d-flex flex-column" ><div style="width:2px;height:100%;background-color:';
  let replaceWithEnd = ';margin-right:12px;"></div></div><div>';
  let colorCount = 0;
  for (let i = 0; i < 10; i++) {
    // variant 1
    if (outHtml.includes(replaceFind1)) {
      let colorNum = colorCount % colors.length;
      let replaceWith = replaceWithStart + colors[colorNum] + replaceWithEnd;
      outHtml = outHtml.replace(replaceFind1, replaceWith);
      // console.log(`found: ${outHtml}`);
      colorCount++;
    } else if (outHtml.includes(replaceFind2)) {
      // variant 2
      let colorNum = colorCount % colors.length;
      let replaceWith = replaceWithStart + colors[colorNum] + replaceWithEnd;
      outHtml = outHtml.replace(replaceFind2, replaceWith);
      colorCount++;
    } else {
      break;
    }
  }
  if (colorCount) {
    return outHtml;
  }
  return html;
};
export const convertPrismicStory = ({item, rests}) => {
  const {date, image, image_url, restaurant} = item
  
  const r = rests.find(item => item.code === restaurant) || {}

  const outRest = {
    code:restaurant,
    name: r.name,
    img: r.img,
    url: r.url,
    address: r.address,
    location: r.location,
    phone: r.phone,
    email: r.email,
    text: r.text,
    text_2: r.text_2,
    cta: r.cta,
    date,
  }

  return outRest
}
export const convertPrismicRest = ({item, rests}) => {
  if (!item) return null
  console.log('item: ', item)
  const {date, day_or_week, restaurant} = item
  const {single_cell} = item // every day of week
  const {single_day_menu} = item // one day of week

  const toHtml = (data) => {
    if (!data) return null
    if (data.html === '') return null
    if (data.html) {
      return replaceDish(data.html)
    }
    const html = prismicH.asHTML(data)
    return replaceDish(html)
  }
  const weekData = {
    monday: toHtml(item.monday_menu),
    tuesday: toHtml(item.tuesday_menu),
    wednesday: toHtml(item.wednesday_menu),
    thursday: toHtml(item.thursday_menu),
    friday: toHtml(item.friday_menu),
  }
  const d = moment(date)
  const day = d.format('YYYY.MM.DD')
  // only one day menu
  const isOneDayMenu = day_or_week === 'Bestimmter Tag'
  if (isOneDayMenu) {
    const wd = d.format('dddd').toLowerCase();
    weekData[wd] = toHtml(single_day_menu)
  }
  const r = rests.find(item => item.code === restaurant) || {}
  // every day one menu
  const isSingle = day_or_week === 'Freier Text'
  const singleCell = toHtml(single_cell)

  const isAllDishes = day_or_week === 'Woche (all dishes)'

  const outRest = {
    code:restaurant,
    name: r.name,
    featured: item.featured,
    img: r.img.url,
    url: r.url,
    address: r.address,
    location: r.location,
    phone: r.phone,
    email: r.email,
    text: r.text,
    text_2: r.text_2,
    cta: r.cta,
    date,
    day,
    weekData,
    isSingle,
    singleCell,
    isAllDishes,
  }

  return outRest
}

export async function callHook({uid, url, params={}}) {
  let response = null;
  try {
    const options = {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      redirect: 'follow',
      headers: { "content-type": "application/json" },
    };
    console.log(`send to: ${url}`);

    // const data = JSON.stringify(params);

    options.body = JSON.stringify({uid, data:params});
    response = await fetch(url, options);
    // console.log(response);
    console.log('status code: ', response.status); // 👉️ 200

    if (!response.ok) {
      console.log(response);
      throw new Error(`Error! status: ${response.status}`);
    }
    return response.text;
  } catch (e) {
    // interface Error {
    //   name: string;
    //   message: string;
    //   stack?: string;
    // }
    const status = response ? response.status : null;
    console.log(e);
    const message = e.message || 'Error';
    const r = {success: false, message, status}
    return r;
  }
}