import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Testimonials = ({ slice }) => {
  const {title} = slice.primary

  const items = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-sm-6">
        <div className="h-100 bg-light rounded p-4">
        <div className="row">
          <div className="col-auto">
            <img className="rounded" style={{objectFit:"cover"}} height="48" width="48" src={item.img.url}/>
          </div>
          <div className="col">
            <h5 className="">{item.restaurant}</h5>
            <h6 className="">{item.name}</h6>
          </div>
          <div className="col-auto">
            <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ac0a08" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/></svg></span>
            <span className="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ac0a08" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/></svg></span>
            <span className="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ac0a08" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/></svg></span>
            <span className="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ac0a08" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/></svg></span>
            <span className="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ac0a08" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/></svg></span>
          </div>
        </div>
        <p className="mt-3 mb-0">{item.text.text}</p>
      </div>
      </div>
    )
  })

  return (
    <div className="bg-white py-5">
      <div className="container py-5">
        <div className="col-lg-8 mx-auto">
          <h3 className="text-center">{title}</h3>
        </div>
        <div className="row g-4 mt-2">
          {items}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyTestimonials on PrismicPageDataBodyTestimonials {
    primary {
      title
    }
    items {
      restaurant
      name
      text {
        text
      }
      img {
        url
      }
    }
  }
`
