import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Steps = ({ slice }) => {
  const {title} = slice.primary

  const items = slice.items.map((item, index) => {
    return (
      <div key={index} className={"col-12 col-sm-6 " + (slice.items.length == 4) ? "col-lg-3" : "col-sm-4"}>
        <div className="h-100 bg-light rounded p-4 d-flex flex-column justify-content-between">
          <div>
            <h5 className="">{item.title}</h5>
            <p className="">{item.text.text}</p>
          </div>
          <div className="d-flex flex-column justify-content-center ratio ratio-1x1">
            <img className="" style={{objectFit:"contain", width:"100%", maxHeight:"240px"}}  src={item.img.url}/>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className="bg-white py-5">
      <div className="container py-5">
        <div className="col-lg-8 mx-auto">
          <h3 className="text-center">{title}</h3>
        </div>
        <div className="row g-4 mt-2">
          {items}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodySteps on PrismicPageDataBodySteps {
    primary {
      title
    }
    items {
      title
      text {
        text
      }
      img {
        url
      }
    }
  }
`
