import * as React from 'react'
import { graphql } from 'gatsby'
import Accordion from 'react-bootstrap/Accordion';
import { PrismicRichText } from '@prismicio/react'

export const AccordionFaq = ({ slice }) => {
  const {title} = slice.primary
  const faq = slice.items.map((item, index) => {
    return (
      <Accordion.Item eventKey={index} key={index} >
        <Accordion.Header><span className="h5">{item.title}</span></Accordion.Header>
        <Accordion.Body>
          <PrismicRichText field={item.text.richText} />
        </Accordion.Body>
      </Accordion.Item>
    )
  })

  return (
    <div className="bg-white py-5">
      <div className={"container py-5"}>
        <h3 className="text-center">{title}</h3>
        <div className="mt-4">
          <Accordion>
            {faq}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyAccordionFaq on PrismicPageDataBodyAccordionFaq {
    primary {
      title
    }
    items {
      title
      text {
        richText
      }
    }
  }
`
