import * as React from 'react'
import { graphql } from 'gatsby'

export const HomepageCover = ({ slice }) => {
  const {cover_title, ribbon, banner_img, cover_img} = slice.primary

  return (
    <div className="bg-dark d-flex flex-column" style={{backgroundImage:"url(" + cover_img.url + ")",backgroundPosition:"center center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
      <div className="homepage-cover-gradient">
        <div className="parent"><h4 className="ribbon">{ribbon}</h4></div>
        <div className="py-5">
          <div className="position-relative container py-5">
            <div className="row">
              <div className="py-5 col-12 col-xl-6 pe-3 pe-lg-5">
                <img className="w-100" style={{objectFit:"contain", maxWidth:"480px"}}  src={banner_img.url}/>
                <h1 className="text-white homepage-cover-text mt-4">{cover_title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHomepageCover on PrismicHomepageDataBodyHomepageCover {
    primary {
      cover_title
      ribbon
      banner_img {
        url
      }
      cover_img {
        url
      }

    }
  }
`
