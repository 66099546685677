import { HomepageCover } from './HomepageCover'
import { ImageAndText } from './ImageAndText'
import { Reviews } from './Reviews'
import { WhatWeOffer } from './WhatWeOffer'
import { Pricing } from './Pricing'
import { Marketing } from './Marketing'
import { Contacts } from './Contacts'
import { TitleAndText } from './TitleAndText'
import { Example } from './Example'
import { Advertising } from './Advertising'
import { WeekMenu } from './WeekMenu'

import { PageCover } from './PageCover'
import { Features } from './Features'
import { Steps } from './Steps'
import { Testimonials } from './Testimonials'
import { PricingNew } from './PricingNew'
import { AccordionFaq } from './AccordionFaq'

export const components = {
  homepage_cover: HomepageCover,
  image_and_text: ImageAndText,
  reviews: Reviews,
  what_we_offer: WhatWeOffer,
  pricing: Pricing,
  marketing: Marketing,
  contacts: Contacts,
  title_and_text: TitleAndText,
  example: Example,
  advertising: Advertising,
  main_slice: WeekMenu,

  page_cover: PageCover,
  features: Features,
  steps: Steps,
  testimonials: Testimonials,
  pricing_new: PricingNew,
  accordion_faq: AccordionFaq,
}
